<template>
  <div class="fullScreen overflowAuto coursesContainer">
    <CoursesHeader v-if="isOamn" />
    <div v-if="isOamn" class="coursesCover"></div>
    <div class="coursesContentContainer">
      <div v-if="isOamn" class="courseTitle">{{ course.course.title }}</div>
      <div class="relative coursesContent">
        <!-- TODO right now themeId is always null -->
        <OamnCourseNavigation
          v-if="isOamn"
          class="z--4"
          :mobileOut="outlineOut"
          :progress="progress"
          @close="outlineOut = false"
        />
        <CourseNavigation
          v-else
          class="z--4"
          :mobileOut="outlineOut"
          :progress="progress"
          @close="outlineOut = false"
        />
        <transition name="fade">
          <div v-if="outlineOut">
            <div
              class="navBar--backdrop fixed opacity--5"
              @click="toggleOutline"
            />
          </div>
        </transition>
        <div
          class="content--outline width--100 flex--end__hor pad--2 contentOutline"
        >
          <button
            class="burgerMenu burgerMenu--spin noDec"
            type="button"
            :class="{ 'is-active': outlineOut }"
            @click="toggleOutline"
          >
            <span class="burgerMenu-box">
              <span class="burgerMenu-inner" />
            </span>
          </button>
        </div>
        <div
          class="content width--100 flex flex--column z--0 courseContent"
          v-if="course.course.title"
        >
          <transition name="fade" mode="out-in">
            <router-view :progress="progress" />
          </transition>
        </div>
      </div>
    </div>
    <Footer v-if="isOamn" />
  </div>
</template>
<script>
import Footer from "@/components/reusable/Footer";
import CoursesHeader from "@/components/views/1_Overview/CoursesHeader";
import CourseNavigation from "@/components/views/2_Course/CourseNavigation";
import OamnCourseNavigation from "@/components/views/2_Course/OamnCourseNavigation";
export default {
  name: "CourseLoader",
  components: { CourseNavigation, OamnCourseNavigation, CoursesHeader, Footer },
  data() {
    return {
      outlineOut: false,
    };
  },
  computed: {
    organizationID() {
      return this.$store.getters.getOrganizationID;
    },
    isOamn() {
      return this.organizationID === "62a8815e42be6e0012bda012";
    },
    theme() {
      return this.$store.getters.getTheme;
    },
    courses() {
      return this.$store.getters.getCourses;
    },
    course() {
      return (
        this.courses?.find(
          (el) => el.course._id === this.$route.params.courseID
        ) || {
          course: {
            title: "",
          },
        }
      );
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    progress() {
      if (!this.courseDetails || !this.courseDetails.course) return 0;
      let total = 0;
      let done = 0;
      this.courseDetails.course.forEach((chapter) => {
        chapter.pages.forEach((page) => {
          total++;
          done += page.done ? 1 : 0;
        });
      });
      return done === 0 ? 0 : Math.round((done / total) * 100);
    },
    page() {
      return this.$route.params.page;
    },
  },
  methods: {
    toggleOutline() {
      this.outlineOut = !this.outlineOut;
    },
  },
  mounted() {
    if (this.courses && !this.courses.length)
      this.$store.dispatch(
        "retrieveCourses",
        this.$route.params.organizationID
      );
    this.$store.dispatch("retrieveCourseDetails", this.$route.params.courseID);
  },
};
</script>
