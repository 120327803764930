<template>
  <div class="courses--header width--100 fixed flex flex--center coursesHeader">
    <div
      class="flex flex--center__vert flex--space__hor courses--header__container"
    >
      <router-link
        :to="`/${$route.params.organizationID}/`"
        class="height--100"
      >
        <img
            :src="theme.bannerLogo.link"
          class="height--90 clickable"
        />
      </router-link>
      <div class="flex flex--center__vert courses--header__desktop">
        <div class="mar--right__2">
          <a
            :href="`https://doguniversity.typeform.com/to/CJMi2QA1#userid=${user._id}&email=${user.email}&platform=web&version=web&platformversion=web`"
            class="noDec color--primary font--s16 font--w700 clickable"
            target="_blank"
          >
            Feedback
          </a>
        </div>
        <div
          :key="action._id"
          class="mar--right__2"
          v-for="action in theme.actions"
        >
          <div
            @click="actionLink(action.link)"
            class="font--s16 font--w700 clickable"
          >
            {{ action.title }}
          </div>
        </div>
        <i
          @click="toggleMainPopup"
          class="ion-md-contact font--s5 clickable"
          v-click-outside="closeMainPopup"
        />
      </div>
      <div class="courses--header__mobile">
        <i
          @click="showMobileNav = true"
          class="ion-md-menu font--s5 clickable"
          v-if="!showMobileNav"
        />
        <i
          @click="showMobileNav = false"
          class="ion-md-close font--s5 clickable"
          v-if="showMobileNav"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        class="courses--header__backdrop flex--column flex--center__vert"
        v-if="showMobileNav"
      >
        <div
          class="bgColor--greyLight pad--2 z--4 font--s2 font--w700 flex flex--column flex--center__vert"
        >
          <div class="flex flex--column content">
            <div
              :key="action._id"
              @click="actionLink(action.link)"
              class="mar--bottom__1"
              v-for="action in theme.actions"
            >
              {{ action.title }}
            </div>
            <div
              @click="$router.push(`/${$route.params.organizationID}/settings`)"
              class="mar--bottom__1"
            >
              Einstellungen
            </div>
            <div @click="logout" class="color--danger">Abmelden</div>
          </div>
        </div>
        <div
          @click="showMobileNav = false"
          class="absolute bgColor--greyDark width--100 height--100 opacity--5 z--0"
        />
      </div>
    </transition>
    <transition name="fade">
      <div class="absolute popup--anchor" v-if="showMainPopup">
        <PopUp :posX="posX" :posY="posY">
          <div
            @click="$router.push(`/${$route.params.organizationID}/settings`)"
            class="popup--item pad--2 clickable font--s16"
          >
            Einstellungen
          </div>
          <div
            @click="logout"
            class="popup--item pad--2 clickable font--s16 color--danger"
          >
            Abmelden
          </div>
        </PopUp>
      </div>
    </transition>
  </div>
</template>
<script>
import PopUp from "@/components/reusable/PopUp";

export default {
  name: "CoursesHeader",
  components: { PopUp },
  data() {
    return {
      posX: null,
      posY: null,

      showMainPopup: false,
      showMobileNav: false,

      link: window.location.href,
    };
  },

  computed: {
    organizationID() {
      return this.$store.getters.getOrganizationID;
    },
    isOamn() {
      return this.organizationID === "62a8815e42be6e0012bda012";
    },
    theme() {
      /* eslint-disable */
      return this.$store.getters.getTheme;
    },
    url() {
      return this.$store.getters.getURL;
    },
    user() {
      return this.$store.getters.getDoguUser;
    },
  },
  methods: {
    toggleMainPopup(e) {
      this.posX = e.clientX;
      this.posY = e.clientY;
      this.showMainPopup = !this.showMainPopup;
    },
    closeMainPopup() {
      this.showMainPopup = false;
    },
    logout() {
      this.$store.dispatch("logoutUser");
      this.$router.push(`/${this.$route.params.organizationID}/login`);
    },
    actionLink(url) {
      window.location = url;
    },
  },
};
</script>
