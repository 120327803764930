<template>
  <div
    class="fullScreen overflowHidden"
    :style="{
      '--primary-color': theme.primary || '#114B5F',
    }"
    :class="{
      oamn: organizationID === '62a8815e42be6e0012bda012',
    }"
  >
    <transition name="fade-move" mode="out-in">
      <div
        class="flex flex--center width--100 height--total__100"
        v-if="!init"
        :key="'loader'"
      >
        <loader :loading="true" :size="'15px'" :color="'#114B5F'" />
      </div>
      <router-view v-if="init && $store.getters.getDoguToken" key="router" />
      <LoginScreenDogU v-if="init && !$store.getters.getDoguToken" key="auth" />
    </transition>
    <div></div>
  </div>
</template>

<script>
import LoginScreenDogU from "@/components/views/0_Authorization/LoginScreenDogU.vue";
export default {
  name: "MainLoader",
  components: { LoginScreenDogU },
  data() {
    return {
      init: false,
    };
  },
  computed: {
    theme() {
      return this.$store.getters.getTheme;
    },
    token() {
      return this.$store.getters.getDoguToken;
    },
    title() {
      return this.$store.getters.getTitle;
    },
    url() {
      return this.$store.getters.getURL;
    },
    organizationID() {
      return this.$store.getters.getOrganizationID;
    },
  },
  async mounted() {
    /* eslint-disable */
    // Init Course Theming -------------------------------------------------------------------------
    this.$store.commit("setOrganizationID", this.$route.params.organizationID);
    // if (localStorage.getItem("x-auth-token-" + this.organizationID)) {
    //   this.$store.commit(
    //     "setToken",
    //     localStorage.getItem("x-auth-token-" + this.organizationID)
    //   );
    //   sessionStorage.removeItem("x-auth-token-" + this.organizationID);
    // } else if (sessionStorage.getItem("x-auth-token-" + this.organizationID)) {
    //   this.$store.commit(
    //     "setToken",
    //     sessionStorage.getItem("x-auth-token-" + this.organizationID)
    //   );
    //   localStorage.removeItem("x-auth-token-" + this.organizationID);
    // }

    const tokenDogu = "x-auth-token-dogu";
    if (localStorage.getItem(tokenDogu)) {
      this.$store.commit("setDoguToken", localStorage.getItem(tokenDogu));
      sessionStorage.removeItem(tokenDogu);
    } else if (sessionStorage.getItem(tokenDogu)) {
      this.$store.commit("setDoguToken", sessionStorage.getItem(tokenDogu));
      localStorage.removeItem(tokenDogu);
    }

    const userDogu = "x-auth-user-dogu";
    if (localStorage.getItem(userDogu)) {
      this.$store.commit("setDoguUser", JSON.parse(localStorage.getItem(userDogu)));
      sessionStorage.removeItem(userDogu);
    } else if (sessionStorage.getItem(userDogu)) {
      this.$store.commit("setDoguUser", JSON.parse(sessionStorage.getItem(userDogu)));
      localStorage.removeItem(userDogu);
    }
    await this.$store.dispatch("setAuthHeader");

    let success = true;
    await this.$store
      .dispatch("retrieveCourseTheme", this.$route.params.organizationID)
      .catch(() => {
        this.$store.commit("setTheme", {
          actions: [],
          primary: "#114B5F",
        });
        success = false;
      });
    if (!success) return;
    document.title = this.title;
    const link = document.createElement("link");
    const oldLink = document.getElementById("dynamic-favicon");
    link.id = "dynamic-favicon";
    link.rel = "shortcut icon";
    link.href = this.theme.faviconLogo.link;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);

    this.init = true;
    this.$forceUpdate();

    // // Trying to keep user loggedIn ------------------------------------------------------------------
    // if (!this.$store.getters.getToken) {
    //   if (this.$route.path != `/${this.$route.params.organizationID}/login`) {
    //     this.$router.push(`/${this.$route.params.organizationID}/login`);
    //   }
    // }
    // // this.init = true;
    //
    // this.$store
    //   .dispatch("refreshToken", true)
    //   .then(() => {
    //     this.init = true;
    //     this.$forceUpdate();
    //   })
    //   .catch(() => {
    //     this.$router.push(`/${this.$route.params.organizationID}/login`);
    //     this.init = true;
    //   });
  },
};
</script>
