export const errorCodes = {
    "400": [
        {
            "code": 1,
            "msg": "Der Nutzer ist bereits registriert"
        },
        {
            "code": 2,
            "msg": "Der Editor kann nicht gefunden werden"
        },
        {
            "code": 3,
            "msg": "Der Member kann nicht gefunden werden"
        },
        {
            "code": 4,
            "msg": "Authentifizierung gescheitert"
        },
        {
            "code": 5,
            "msg": "Falsche E-Mail oder falsches Passwort"
        },
        {
            "code": 6,
            "msg": "Der Nutzer ist bereits ein Teil der Organisation"
        },
        {
            "code": 7,
            "msg": "Die Organisation kann nicht gefunden werden"
        },
        {
            "code": 8,
            "msg": "Du kannst dich selbst nicht aus der Organisation entfernen"
        },
        {
            "code": 9,
            "msg": "Der Trainer kann nicht gefunden werden"
        },
        {
            "code": 10,
            "msg": "Der Kurs kann nicht gefunden werden"
        },
        {
            "code": 11,
            "msg": "Diese Lektion ist nicht Teil des Kapitels"
        },
        {
            "code": 12,
            "msg": "Der Trainer ist noch in paar Kursen hinterlegt"
        },
        {
            "code": 13,
            "msg": "So können Kapitel oder Lektionen nicht verschoben werden"
        },
        {
            "code": 14,
            "msg": "Dieser Dateityp kann nicht hochgeladen werden"
        },
        {
            "code": 15,
            "msg": "Die Lektion kann nicht gefunden werden"
        },
        {
            "code": 16,
            "msg": "Das Titelbild existiert nicht"
        },
        {
            "code": 17,
            "msg": "Der Nutzer ist kein Teil des Kurses"
        },
        {
            "code": 18,
            "msg": "Es nicht erlaubt ein Kursupdate bei diesem Nutzer durchzuführen"
        },
        {
            "code": 19,
            "msg": "Der Nutzer kann nicht gefunden werden"
        },
        {
            "code": 20,
            "msg": "Das Passwort muss mindestens 8 Zeichen lang sein"
        },
        {
            "code": 21,
            "msg": "Das Passwort muss mindestens ein Sonderzeichen oder eine Zahl enthalten."
        },
        {
            "code": 22,
            "msg": "Diese Datei existiert nicht mehr."
        },
        {
            "code": 23,
            "msg": "Diese Organisation kann nicht gefunden werden."
        },
        {
            "code": 24,
            "msg": "Das Video wurde bereits gelöscht."
        },
        {
            "code": 25,
            "msg": "Die WebHook ist aktiviert, jedoch nicht zu erreichen."
        },
        {
            "code": 26,
            "msg": "Der Gutscheincode ist ungültig"
        },
        {
            "code": 27,
            "msg": "Es gibt bereits ein laufendes Abo für diese Organisation"
        },
        {
            "code": 28,
            "msg": "Es ist keine Zahlungsart hinterlegt."
        },
        {
            "code": 29,
            "msg": "Es ist nicht erlaubt."
        },
        {
            "code": 30,
            "msg": "Es gibt kein laufendes Abonnement"
        },
        {
            "code": 31,
            "msg": "Der Plan kann nicht gefunden werden"
        },
        {
            "code": 32,
            "msg": "Kein aktives Abonnement"
        },
        {
            "code": 33,
            "msg": "Es muss eine Rechnungsadresse festgelegt sein."
        },
        {
            "code": 34,
            "msg": "Diese Zahlungsart kann nicht gefunden werden."
        },
        {
            "code": 35,
            "msg": "Die letzte Zahlungsart kann nicht gelöscht werden. Füge erst eine neue dazu um diese zu löschen."
        },
        {
            "code": 36,
            "msg": "Eine Kreditkarte, welche als Standard festgelegt ist kann nicht gelöscht werden. Lege doch bitte erst eine anderen Karte als Standard an um diese zu löschen."
        },
        {
            "code": 37,
            "msg": "Für die Organisation gibt es noch ein laufendes Abonnement."
        },
        {
            "code": 38,
            "msg": "Die Registrierung ist zum jetzigen Zeitpunkt deaktiviert."
        },
        {
            "code": 39,
            "msg": "Dieses Element existiert nicht."
        },
        {
            "code": 40,
            "msg": "Du hast bereits ein Zertifikat generiert."
        },
        {
            "code": 41,
            "msg": "Um das Zertifikat zu generieren musst du zuerst alle Kapitel abschließen"
        },
        {
            "code": 42,
            "msg": "In diesem Kurs ist es nicht möglich eine Zertifizierung abzuschließen"
        },
        {
            "code": 43,
            "msg": "Dieses Zertifikat existiert nicht."
        },
        {
            "code": 44,
            "msg": "Das E-Mail Template ist nicht valide. Es benötigt folgendes Element {{password}}"
        },
        {
            "code": 45,
            "msg": "Bitte prüfe den DNS Eintrag deiner Domain. Dieser zeigt nicht auf Okourse.com. Es Kann einige Minuten dauern, bis dein Domainanbieter die Änderung verarbeitet hat."
        },
        {
            "code": 46,
            "msg": "Es handelt sich um keinen validen Hostnamen der Domain"
        },
        {
            "code": 47,
            "msg": "Dieser Hostname wurde bereits einer anderen Organisation hinzugefügt. Bitte entferne die Domain erst von der anderen Organisation bevor du die Domain dieser hinzufügen kannst."
        },
        {
            "code": 48,
            "msg": "Es konnte keine Organisation mit diesem Hostnamen gefunden werden."
        },
        {
            "code": 49,
            "msg": "Du besitzt nicht ausreichend Rechte in dieser Organisation um diese Aktion auszuführen."
        },
        {
            "code": 50,
            "msg": "Es wurde keine Datei mit hochgeladen oder es handelt sich um keine CSV Datei."
        },
        {
            "code": 51,
            "msg": "Das Angebot konnte nicht gefunden werden"
        },
        {
            "code": 52,
            "msg": "Diese externen Produkt Ids wurden bereits einem anderen Produkt zugeordnet."
        },
        {
            "code": 53,
            "msg": "Die Ids der Kurse sind nicht valide."
        },
        {
            "code": 54,
            "msg": "Ein Angebot benötigt immer mindestens einen Kurs."
        },
        {
            "code": 55,
            "msg": "Die übergebenen Typen von Events sind nicht valide."
        },
        {
            "code": 56,
            "msg": "Dieser Webhook-Endpunkt existiert nicht oder du verfügst nicht über die nötigen Rechte!"
        },
        {
            "code": 57,
            "msg": "Dieses Event existiert nicht oder du bist nicht autorisiert."
        },
        {
            "code": 58,
            "msg": "Die Umsatzsteuernummer ist nicht valide."
        },
        {
            "code": 59,
            "msg": "Die Bestellung konnte nicht gefunden werden"
        }
    ],
    "401": [
        {
            "code": 1,
            "msg": "Die E-Mail oder das Passwort ist fehlerhaft."
        },
        {
            "code": 2,
            "msg": "Das Passwort ist falsch. Das Passwort wurde per E-Mail zugesendet."
        },
        {
            "code": 3,
            "msg": "Account ist gesperrt."
        },
        {
            "code": 5,
            "msg": "Aus Sicherheitsgründen wird für dieses Login ein Code benötigt. Dieser wurde gerade an die Nutzeremail gesendet."
        },
        {
            "code": 6,
            "msg": "Ungültiger Code."
        },
        {
            "code": 7,
            "msg": "Der Code ist bereits abgelaufen."
        },
        {
            "code": 10,
            "msg": "Es wurde kein Token mitübergeben."
        },
        {
            "code": 11,
            "msg": "Du bist nicht autorisiert."
        },
        {
            "code": 12,
            "msg": "Falsche Nutzerrolle."
        },
        {
            "code": 13,
            "msg": "Nicht authorisiert."
        }
    ]
}

export const theme = {
    theme: {
        bannerLogo: {
            path: "/resources/images/organizations/5ebe682fb308810011922d13/1592693219957p5X5UTsFPSQO8dv.png",
            link: "/header.png"
        },
        faviconLogo: {
            path: "/resources/images/organizations/5ebe682fb308810011922d13/1594755167348jbtEuSITzx1G9OS.png",
            link: "/favicon.png"
        },
        legal: {
            imprint: "https://doguniversity.de/impressum/",
            privacy: "https://doguniversity.de/datenschutz/",
            termsOfUse: "https://doguniversity.de/agb/"
        },
        libraryLayout: "grid",
        themeId: null,
        primary: "#97BB54",
        accent: "#748A3E",
        info: "Unser Mitgliederbereich ist vor Kurzem umgezogen. Solltest du dein neues Passwort nicht haben, klicke bitte auf „Passwort vergessen?“ und generiere es dir so selbst neu.",
        actions: [
            {
                title: "Zur Doguniversity",
                link: "https://doguniversity.de/",
                _id: "609406ca96aa650011022cbc"
            },
            {
                title: "Zur App",
                link: "https://doguniversity.de/app/",
                _id: "609406ca96aa650011022cbd"
            }
        ]
    },
    title: "Doguniversity",
}