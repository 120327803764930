<template>
  <div class="fullScreen flex flex--column flex--center__vert overflowAuto">
    <div
      class="courses--wrapper height--100 flex--grow mar--bottom__2 coursesWrapper"
    >
      <div class="font--s3 font--w700 mar--bottom__2 courses--title">
        Meine Kurse
      </div>
      <div v-if="courses == null">
        <div
          class="flex flex--center width--100 height--total__100"
          :key="'loader'"
        >
          <loader :loading="true" :size="'15px'" :color="'#114B5F'" />
        </div>
      </div>
      <div
        class="flex flex--center font--s16 height--50__total"
        v-if="courses && !courses.length"
      >
        Du hast noch keine Kurse gekauft.
      </div>
      <div
        class="grid"
        :class="[
          $store.getters.getTheme.libraryLayout === 'grid'
            ? 'itemGrid'
            : 'itemList',
        ]"
        v-if="courses && courses.length"
      >
        <div
          class="flex flex--center"
          v-for="course in courses"
          :key="course.course._id"
        >
          <div
            class="card width--100 height--100 clickable grid relative courseCard"
            :class="[
              $store.getters.getTheme.libraryLayout === 'grid'
                ? 'itemGrid--inner'
                : 'itemList--inner',
            ]"
            @click="forward(`/Course/${course.course._id}`)"
          >
            <div class="width--100 overflowHidden aspect--169">
              <img
                class="card--img absolute top--0"
                :src="`${course.course.thumbnail.path}`"
                v-if="course.course.thumbnail.path"
              />
            </div>
            <div class="flex flex--column pad--2 height--100">
              <div class="font--s2 font--w700 mar--bottom__1">
                {{ course.course.title }}
              </div>
              <div
                class="text--overflow__ellipse max--height__10 overflowHidden font--s14"
              >
                {{ course.course.description }}
              </div>
              <div class="flex--grow" />
            </div>
          </div>
        </div>
        <!--                <div class="flex width&#45;&#45;100" v-if="$route.params.organizationID === '5ebe682fb308810011922d13'">-->
        <!--                  <div class="card flex flex&#45;&#45;column flex&#45;&#45;center width&#45;&#45;100 pad&#45;&#45;2">-->
        <!--                    <p class="font&#45;&#45;s2 mar&#45;&#45;bottom__1">Dir fehlen Kurse?</p>-->
        <!--                    <AccentButton title="Kurse importieren" @onClick="showImportModal = true" />-->
        <!--                  </div>-->
        <!--                </div>-->
      </div>
    </div>

    <Footer />

    <transition name="fade">
      <Modal
        title="Kurse importieren"
        v-if="showImportModal"
        @toggle="showImportModal = !showImportModal"
      >
        <div class="flex flex--column width--100 height--100 pad--4">
          <div class="mar--bottom__1 font--s16">
            Sollten deine Trainings nicht in der Bibliothek angezeigt werden
            bzw. nicht freigeschaltet sein, kannst du sie mit Hilfe der
            Bestellnummer und deinem Nachnamen manuell importieren.
          </div>
          <label for="name" class="form--label">Nachname</label>
          <input
            id="name"
            type="text"
            class="form--input form--input__text mar--bottom__1"
            v-model="lastName"
          />
          <label for="order" class="form--label">Bestellnummer</label>
          <input
            id="order"
            type="text"
            class="form--input form--input__text mar--bottom__2"
            v-model="orderNr"
          />
          <AccentButton
            title="Kurse importieren"
            filled="true"
            :loading="loading"
            @onClick="importCourses"
          />
        </div>
      </Modal>
    </transition>
  </div>
</template>
<script>
import Footer from "@/components/reusable/Footer";
import AccentButton from "@/components/reusable/AccentButton";
import Modal from "@/components/reusable/Modal";
export default {
  name: "CoursesOverview",
  components: { Modal, AccentButton, Footer },
  data() {
    return {
      showImportModal: false,
      orderNr: null,
      lastName: null,
      loading: false,
    };
  },
  computed: {
    courses() {
      return this.$store.getters.getCourses;
    },
    url() {
      return this.$store.getters.getURL;
    },
  },
  methods: {
    forward(url) {
      const organizationID = this.$route.params.organizationID;
      this.$router.push("/" + organizationID + url);
    },
    async importCourses() {
      if (!this.lastName || !this.orderNr) {
        this.$store.commit("addToast", {
          type: "warning",
          title: "Bitte alle Felder ausfüllen",
        });
        return;
      }
      this.loading = true;
      await this.$store.dispatch("importCourses", {
        orderId: this.orderNr,
        lastName: this.lastName,
      });
      await this.$store.dispatch(
        "retrieveCourses",
        this.$route.params.organizationID
      );
      this.loading = false;
      this.showImportModal = false;
    },
  },
};
</script>
